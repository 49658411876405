import axios from 'axios';

export const SET_WISHLIST = 'SET_WISHLIST';
export const ADD_ITEM_WISHLIST = 'ADD_ITEM_WISHLIST';
export const REMOVE_ITEM_WISHLIST = 'REMOVE_ITEM_WISHLIST';
export const REMOVE_ITEM_ALL_WISHLIST = 'REMOVE_ITEM_ALL_WISHLIST';

export function setWishlist(payload) {
    return {
        type: SET_WISHLIST,
        payload: payload
    };
}


export function addItem(id, content) {
    return {
        type: ADD_ITEM_WISHLIST,
        item: id,
        content: content
    };
}

export function removeItem(id, content) {
    return {
        type: REMOVE_ITEM_WISHLIST,
        item: id,
        content: content
    };
}

export function removeAllItems() {
    return {
        type: REMOVE_ITEM_ALL_WISHLIST
    };
}

export const fetchWishlist = () => {
    return dispatch => {
        axios.get(`${process.env.REACT_APP_API_KEY}/wishlists?token=${localStorage.getItem("token")}`)
        .then(res => {
            dispatch(setWishlist(res.data));
        }).catch(err => {
            console.log(err, err?.response?.data?.error?.message);
        });
    };
}

export const addToWishlist = (id, content) => {
    return dispatch => {

        if (localStorage.getItem("visitor") === "true") {
            dispatch(addItem(id, content));
        }
        else {
            axios.post(`${process.env.REACT_APP_API_KEY}/wishlists`,
            {
                product_id: id,
                product_content: content
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem("token")}`
                }
            })
            .then(res => {
                dispatch(addItem(id, content));
            }).catch(err => {
                console.log(err, err?.response?.data?.error?.message);
            });
        }

    };
}

export const removeFromWishlist = (id, content) => {
    return dispatch => {
        
        if (localStorage.getItem("visitor") === "true") {
            dispatch(removeItem(id, content));
        }
        else {
            axios.delete(`${process.env.REACT_APP_API_KEY}/wishlists`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem("token")}`
                },
                data: {
                    product_id: id,
                    product_content: content
                }
            })
            .then(res => {
                dispatch(removeItem(id, content));
            }).catch(err => {
                console.log(err.response.data.error.message);
            });
        }
    };
}

export const clearWishlist = () => {
    return dispatch => {
        dispatch(removeAllItems());
    };
}