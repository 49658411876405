import React from "react";
import Image from "./Image";

const Loader = () => {
  return (
    <div className="loader__parent">
      <Image
        className="logo"
        src={require("../../../assets/logo--simple.svg").default}
        alt="Logo."
      />
    </div>
  );
};

export default Loader;