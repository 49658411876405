import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addToWishlist } from "../../redux/actions/wishlist";
import * as Scroll from 'react-scroll';
import { deleteProduct } from "../../redux/actions/product";
import { Image } from "./utils";

const ProductCard = ({ product }) => {
    const dispatch = useDispatch();

    const account = useSelector(state => state.account.data)
    const [hovered, setHovered] = useState(false);

    return (
        <Link className="product" style={{
            "opacity": product.stock > 0 ? 1 : 0.5,
        }} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} to={`/product?id=${product?.id}`}>
            <div className="image">
                <Image className="image" src={product?.images && (product?.images[0])} alt="product" />
            </div>

            <div className="d-flex flex-row justify-content-between w-100 p-2">
                <div>
                    <div className="title">
                        {product.title.length > 20 ? product.title.substring(0, 20) + "..." : product.title}
                    </div>
                    <div className="description">
                        {product.description.length > 20 ? product.description.substring(0, 20) + "..." : product.description}
                    </div>
                </div>
                <div className="price w-fit">
                    {
                        product?.content?.promo > 0 ?
                            <div className="text-promo">
                                <strike>{product.price.toLocaleString("ro-RO", { style: "currency", currency: "RON" })}</strike> <span className="promo"> - {product.content?.promo}%</span> {(Math.floor((product.price - product.price * product.content?.promo / 100) * 100) / 100).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                            </div>
                            :
                            <>
                                {product.price.toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                            </>
                    }
                </div>

            </div>

            <div className="d-flex flex-row justify-content-center gap-4">
                {
                    account?.type == "master" &&
                    <Link to={`/edit?id=${product?.id}`}>
                        <span className="icon material-icons-outlined">
                            edit
                        </span>
                    </Link>
                }

                {
                    account?.type == "master" &&
                    <div onClick={() => {
                        if (window.confirm("Are you sure you want to delete this product?")) {
                            dispatch(deleteProduct({ id: product.id }));
                        }
                    }}>
                        <span className="icon material-icons-outlined">
                            delete
                        </span>
                    </div>
                }
            </div>

            {/* <div className="d-flex flex-row justify-content-center gap-2">
                <div className="button d-flex flex-row justify-content-center gap-2">
                  <Image
                    className="button__img"
                    src={require("../../assets/cart.svg").default}
                    alt="My shopping cart."
                  />
                  <div className="text-start text-uppercase">
                      Adauga<br /> in cos
                  </div>
                </div>

                <div className="button d-flex flex-row gap-2">
                  <Image
                    className="button__img"
                    src={require("../../assets/wishlist.svg").default}
                    alt="My wishlist."
                  />
                  <div className="text-start text-uppercase">
                      Adauga<br /> la favorite
                  </div>
                </div>
            </div> */}
        </Link>
    )
};

export default ProductCard;