import {
    SET_CART,
    ADD_ITEM_CART,
    REMOVE_ITEM_CART,
    REMOVE_ITEM_ALL_CART
} from '../actions/cart';

import updateObject from "../updateObject";

const initialState = {
    data: JSON.parse(localStorage.getItem("cart")) || []
};

const saveCartToLocalStorage = (cart) => {
    localStorage.setItem("cart", JSON.stringify(cart));
    window.dispatchEvent(new Event('storage'));
};

const isValidProduct = (product) => 
    product && product.content && product.amount;

export function set(state, action) {
    const { payload } = action;

    if (!payload.every(isValidProduct)) {
        saveCartToLocalStorage([]);
        return { data: [] };
    }

    saveCartToLocalStorage(payload);
    return updateObject(state, { data: payload });
}

export function addItem(state, action) {
    const { item, content, amount } = action;
    const existingProduct = state.data.find(product => product.id === item && JSON.stringify(product.content) === JSON.stringify(content));

    if (existingProduct) {
        existingProduct.amount += amount;
    } else {
        state.data.push({ id: item, amount, content });
    }

    saveCartToLocalStorage(state.data);
    return updateObject(state, { data: [...state.data] });
}

export function removeItem(state, action) {
    const { item, content } = action;
    const existingProduct = state.data.find(product => product.id === item && JSON.stringify(product.content) === JSON.stringify(content));

    if (existingProduct) {
        existingProduct.amount--;
        if (existingProduct.amount <= 0) {
            state.data = state.data.filter(product => product.id !== item || product.content !== content);
        }
    }

    saveCartToLocalStorage(state.data);
    return updateObject(state, { data: [...state.data] });
}

export function removeAllItems(state) {
    saveCartToLocalStorage([]);
    return updateObject(state, { data: [] });
}

export function cartReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CART: return set(state, action);
        case ADD_ITEM_CART: return addItem(state, action);
        case REMOVE_ITEM_CART: return removeItem(state, action);
        case REMOVE_ITEM_ALL_CART: return removeAllItems(state);
        default: return state;
    }
}
