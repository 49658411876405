import {
    SET_WISHLIST,
    ADD_ITEM_WISHLIST,
    REMOVE_ITEM_WISHLIST,
    REMOVE_ITEM_ALL_WISHLIST
} from '../actions/wishlist';

import updateObject from "../updateObject";

const initialState = {
    data: JSON.parse(localStorage.getItem("wishlist")) || []
};

const saveWishlistToLocalStorage = (wishlist) => {
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
    window.dispatchEvent(new Event('storage'));
};

const isValidProduct = (product) => 
    product && product.content;

export function set(state, action) {
    const { payload } = action;

    if (!payload.every(isValidProduct)) {
        saveWishlistToLocalStorage([]);
        return { data: [] };
    }

    saveWishlistToLocalStorage(payload);
    return updateObject(state, { data: payload });
}

export function addItem(state, action) {
    const { item, content } = action;
    const existingProduct = state.data.find(product => product.id === item && product.content === content);

    if (!existingProduct) {
        state.data.push({ id: item, content });
    }

    saveWishlistToLocalStorage(state.data);
    return updateObject(state, { data: [...state.data] });
}

export function removeItem(state, action) {
    const { item, content } = action;
    const updatedData = state.data.filter(product => product.id !== item || product.content !== content);

    saveWishlistToLocalStorage(updatedData);
    return updateObject(state, { data: [...updatedData] });
}

export function removeAllItems(state) {
    saveWishlistToLocalStorage([]);
    return updateObject(state, { data: [] });
}

export function wishlistReducer(state = initialState, action) {
    switch (action.type) {
        case SET_WISHLIST: return set(state, action);
        case ADD_ITEM_WISHLIST: return addItem(state, action);
        case REMOVE_ITEM_WISHLIST: return removeItem(state, action);
        case REMOVE_ITEM_ALL_WISHLIST: return removeAllItems(state);
        default: return state;
    }
}
