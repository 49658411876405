import React, { useRef, useEffect, useState } from 'react';
import { Image } from './utils';

const ImageUploader = ({setImage, value, className}) => {
    const [preview, setPreview] = useState(value);
    const [imageFile, setImageFile] = useState(null);
    const hiddenFileInput = useRef(null);
    const reader = new FileReader();

    reader.onloadend = e => {
        setPreview(reader.result);
    };

    useEffect(() => {
        if (value instanceof Blob) {
            reader.readAsDataURL(value);
        }
    }, [value]);

    return (
        <div onClick={() => hiddenFileInput.current.click()}>
            {
                preview ?
                <Image className={className ? className : "slide_image bg-light border"} src={preview} alt="slide" />
                :
                <div className={className ? className : "slide_image bg-light border p-3 d-flex flex-column justify-content-center"}>
                    <span className="material-icons-outlined m-auto display-1">image</span>
                </div>
            }
            <input
                ref={hiddenFileInput}
                accept="image/*"
                type="file"
                style={{display: 'none'}}
                onChange={(event) => {
                    if (event.target.files[0]) {
                        setImage(event.target.files[0]);
                    }
                }}
            /> 
        </div>
    )
}

export default ImageUploader;